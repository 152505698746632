@font-face {
  font-family: "Mona Sans";
  src: url("../public/Mona-Sans.woff2") format("woff2 supports variations"),
    url("../public/Mona-Sans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button:before {
  color: white !important; /* non-active dots color */
  font-size: 9px !important;
}
.slick-dots li.slick-active button:before {
  color: green !important; /* active dot color */
}
